<template>
  <div class="login">
    <full-screen-loading v-if="loading"/>

    <div v-else class="container">
      <div class="logo">
        <img src="@/assets/tireview-logo-bw.png" alt="TireView LIVE">
      </div>
      <div class="form">
        <text-input type="number" v-model="username" class="form-item" label="Employee ID" autocomplete="off" @keyup="autoTab" ref="username"/>
        <text-input v-model="password" class="form-item" type="password" label="Password" autocomplete="off" @keyup.enter="login" ref="password"/>

        <info-box class="error" type="error" :text="error"/>

        <form-button class="login-btn" @click="login" theme="dark">Login</form-button>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { load as recaptchaLoader, getInstance as getRecaptcha } from 'recaptcha-v3'
import FullScreenLoading from '@/components/FullScreenLoading.vue'
import TextInput from '@/components/TextInput.vue'
import FormButton from '@/components/FormButton.vue'
import InfoBox from '@/components/InfoBox.vue'
import { getAuthorizedApp } from '../lib/authorizedApps'

export default {
  name: 'Login',

  components: {
    FullScreenLoading,
    TextInput,
    FormButton,
    InfoBox
  },

  methods: {
    login () {
      this.loading = true
      this.error = null

      this.$apollo.mutate({
        mutation: gql`
          mutation ($employeeId: String!, $password: String!, $appName: String, $recaptchaToken: String) {
            auth {
              token: login(employeeId: $employeeId, password: $password, appName: $appName, recaptchaToken: $recaptchaToken) {
                hash
              }
            }
          }
        `,
        variables: {
          employeeId: this.username,
          password: this.password,
          appName: this.appName,
          recaptchaToken: this.recaptchaToken
        }
      }).then(response => {
        setTimeout(() => {
          const token = response.data.auth.token.hash
          localStorage.setItem('token', token)

          this.redirectToApp(token)
        }, 3000)
      }).catch(async error => {
        this.username = ''
        this.password = ''
        this.error = error.message

        this.loading = false

        this.$nextTick().then(() => {
          this.$refs.username.focus()
        })

        this.recaptchaToken = await getRecaptcha().execute('login')
      })
    },

    redirectToApp (token) {
      const app = getAuthorizedApp(this.appName)
      const url = new URL(app.configs.url + this.returnURI)

      url.searchParams.append('token', token)

      location.replace(url.toString())
    },

    autoTab () {
      if (this.username.length === 8) {
        this.$refs.password.focus()
      }
    },

    autoFocus () {
      this.$nextTick().then(() => {
        this.$refs.username.focus()
      })
    }
  },

  computed: {
    appName () {
      return this.$route.query.app
    },

    returnURI () {
      return this.$route.query.return_uri ?? '/'
    }
  },

  data () {
    return {
      username: '',
      password: '',
      recaptchaToken: '',
      error: null,
      loading: false
    }
  },

  mounted () {
    this.autoFocus()

    recaptchaLoader(process.env.VUE_APP_RECAPTCHA_KEY).then(async recaptcha => {
      this.recaptchaToken = await recaptcha.execute('login')
    })
  }
}
</script>

<style lang="less" scoped>
@import '../assets/media-queries.less';

.login {
  .container {
    background-color: fade(contrast(contrast(@main-color)), 20%);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    padding: 35px;
    border-radius: 10px;
    width: 725px;

    @media @phone {
      background: transparent;
      flex-direction: column;
      position: unset;
      transform: unset;
      top: unset;
      left: unset;
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
    }

    > div {
      flex: 1;
    }

    .logo {
      padding-right: 35px;
      margin-right: 35px;
      border-right: 1px solid fade(contrast(@main-color), 3%);

      @media @phone {
        text-align: center;
        padding: unset;
        margin: 40px 0 60px 0;
        border: unset;
      }

      img {
        width: 300px;
      }
    }

    .form {
      .form-item {
        margin-bottom: 10px;
      }

      .error {
        margin-top: 20px;
      }

      .login-btn {
        margin-top: 30px;
      }
    }
  }
}
</style>
