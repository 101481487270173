import { render, staticRenderFns } from "./FullScreenLoading.vue?vue&type=template&id=899d1d10&scoped=true&"
import script from "./FullScreenLoading.vue?vue&type=script&lang=js&"
export * from "./FullScreenLoading.vue?vue&type=script&lang=js&"
import style0 from "./FullScreenLoading.vue?vue&type=style&index=0&id=899d1d10&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "899d1d10",
  null
  
)

export default component.exports