<template>
  <button class="form-button" @click="$emit('click', $event)">
    <slot/>
  </button>
</template>

<script>
export default {
  name: 'FormButton'
}
</script>

<style lang="less" scoped>
.form-button {
  width: 100%;
  border: none;
  padding: 15px;
  font-family: Rajdhani, sans-serif;
  font-size: 15pt;
  font-weight: 800;
  background-color: tint(@main-color, 5%);
  color: contrast(@main-color);
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 200ms;

  &:hover {
    background-color: lighten(@main-color, 3%);
  }

  &:active {
    background-color: darken(@main-color, 3%);
  }

  &:disabled {
    opacity: 0.3;
    background-color: tint(@main-color, 5%);
    cursor: default;
  }
}
</style>
