import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client/core'

import Vue from 'vue'
import VueApollo from 'vue-apollo'

Vue.use(VueApollo)

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_GRAPHQL_API_URL
})

export const apolloCache = new InMemoryCache()

export const apolloClient = new ApolloClient({
  link: httpLink,
  cache: apolloCache
})

export default function () {
  return new VueApollo({
    defaultClient: apolloClient
  })
}
