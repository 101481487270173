<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  font-family: Roboto, sans-serif;
  width: 100%;
  height: 100%;
  background: @main-color;
}

a {
  text-decoration: none;
  color: @accent-color;

  &:hover {
    text-decoration: underline;
  }
}
</style>
