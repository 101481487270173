<template>
  <div class="full-screen-loading">
    <div class="container">
      <div class="logo tireview">
        <img src="@/assets/tireview-logo-bw.png" alt="TireView LIVE™">
      </div>
      <div class="loading">
        <div class="moving-bar"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FullScreenLoading'
}
</script>

<style lang="less" scoped>
@import '../assets/media-queries.less';

.full-screen-loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle, @main-color 0%, darken(@main-color, 90%) 100%);

  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    .logo {
      &.tireview img {
        width: 20vw;

        @media @phone {
          width: 60vw;
        }
      }

      &.fast img {
        width: 18vw;
      }
    }

    .loading {
      background-color: fade(contrast(@main-color), 10%);
      width: 100%;
      height: 3px;
      margin: 5px auto;
      overflow: hidden;
      position: relative;

      .moving-bar {
        background: fade(contrast(@main-color), 30%);
        width: 30%;
        height: 100%;
        position: absolute;
        left: 0;
        animation: moving-bar 1.5s infinite;
      }
    }
  }
}

@keyframes moving-bar {
  0% {
    left: 0;
    width: 0;
  }
  50% {
    width: 80%;
  }
  100% {
    left: 100%;
    width: 0;
  }
}
</style>
