export const apps = [
  {
    name: 'sysadmin',
    configs: {
      url: process.env.VUE_APP_AUTHORIZED_APP_URL_SYSADMIN ??
        'http://localhost:8081'
    }
  },
  {
    name: 'fast',
    configs: {
      url: process.env.VUE_APP_AUTHORIZED_APP_URL_FAST ??
        'http://localhost:8082'
    }
  },
  {
    name: 'tvl-prog',
    configs: {
      url: process.env.VUE_APP_AUTHORIZED_APP_URL_TVL_PROG ??
        'http://localhost:8099'
    }
  },
  {
    name: 'drawing-tool',
    configs: {
      url: process.env.VUE_APP_AUTHORIZED_APP_URL_DRAWING_TOOL ??
        'http://localhost:8008'
    }
  },
  {
    name: 'roi-calculator',
    configs: {
      url: process.env.VUE_APP_AUTHORIZED_APP_URL_ROI_CALCULATOR ??
        'http://localhost:5173'
    }
  }
]

export function getAuthorizedApp (appName) {
  return appName === undefined
    ? apps[0]
    : apps.find(i => i.name === appName)
}
