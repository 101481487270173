<template>
  <div>
    <transition name="slide-down-fade">
      <div v-if="options.text">
        <div v-if="options.type === 'info2'" class="info-box-2">
          <div class="icon">
            <i class="fas fa-info-circle"></i>
          </div>
          <div v-html="options.text"></div>
        </div>

        <div v-else class="info-box" :class="options.type">
          <i v-if="options.type === 'success'" class="fas fa-check-circle"></i>
          <i v-if="options.type === 'error'" class="fas fa-do-not-enter"></i>
          <span v-html="options.text"></span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'InfoBox',

  props: {
    type: {
      type: String,
      default: 'info',
      required: false
    },
    text: {
      required: true
    }
  },

  computed: {
    options () {
      if (this.text !== null && this.text.startsWith('::')) {
        return {
          type: this.text.substr(2, this.text.indexOf(' ') - 2),
          text: this.text.substr(this.text.indexOf(' ') + 1)
        }
      } else {
        return {
          type: this.type,
          text: this.text
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.info-box-2 {
  display: flex;
  margin: 10px 0;
  color: fade(contrast(@main-color), 40%);

  .icon {
    margin-right: 8px;
    font-size: 15pt;
    opacity: 0.5;
  }
}

.info-box {
  color: fade(contrast(@main-color), 40%);
  text-align: center;
  margin: 10px 0;

  &.success {
    color: @success-color;
  }

  &.error {
    color: @danger-color;
  }

  i {
    margin-right: 8px;
    opacity: 0.5;
  }
}
</style>
