<template>
  <div class="text-input" :class="{invalid: invalid !== null}">
    <label>{{label}}</label>
    <input v-bind="$attrs" v-on="listeners" ref="input" autocomplete="off">

    <button v-if="isPassword" class="toggle-password" tabindex="-1" @click="togglePassword">
      <i v-if="passwordVisible" class="fal fa-eye"></i>
      <i v-else class="fal fa-eye-slash"></i>
    </button>

    <div v-if="invalid !== null" class="error">
      <i class="fas fa-exclamation-circle"></i>
      {{invalid}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextInput',
  inheritAttrs: false,

  props: {
    label: {
      type: String,
      default: null,
      required: false
    },
    invalid: {
      default: null,
      required: false
    }
  },

  methods: {
    focus () {
      this.$refs.input.focus()
    },

    togglePassword () {
      this.passwordVisible = !this.passwordVisible

      if (this.passwordVisible) {
        this.$attrs.type = 'text'
      } else {
        this.$attrs.type = 'password'
      }
    }
  },

  computed: {
    listeners () {
      return {
        ...this.$listeners,
        input: e => {
          this.$emit('input', e.target.value)
          this.passwordVisible = false
        }
      }
    }
  },

  data () {
    return {
      isPassword: false,
      passwordVisible: false
    }
  },

  mounted () {
    this.isPassword = (this.$attrs.type ?? 'text') === 'password'
  }
}
</script>

<style lang="less" scoped>
.text-input {
  label {
    color: contrast(@main-color);
    font-family: Rajdhani, sans-serif;
  }

  input {
    border: 1px solid transparent;
    border-radius: 8px;
    background: fade(contrast(@main-color), 10%);
    font-size: 15pt;
    padding: 10px;
    color: contrast(@main-color);
    outline: none;
    font-family: inherit;
    width: 100%;
    transition: all 200ms;

    &:focus {
      border-color: @accent-color;
    }

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &::-webkit-calendar-picker-indicator {
      opacity: 0.5;
      filter: invert(1);
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  .toggle-password {
    float: right;
    background: transparent;
    color: contrast(@main-color);
    border: none;
    font-size: 15pt;
    margin: 12px 0px 0px -35px;
    clear: both;
    z-index: 1;
    cursor: pointer;
    position: absolute;
    opacity: 0.4;
    transition: opacity 200ms;
    font-weight: 100;

    &:hover {
      opacity: 1;
    }
  }

  .error {
    color: #ff5f5f;
    padding-left: 8px;
    padding-top: 5px;
  }

  &.invalid {
    label {
      color: #ff5f5f;
    }
    input {
      border-color: #ff5f5f;
    }
  }
}
</style>
